<template>
  <div class="bg1">
    <li class="list-none h-20"></li>

    <div v-if="!isMobile" :style="{
      width: '720px', 
      height: '90px', 
      cursor: 'pointer', 
      color: 'white', 
      fontFamily: 'Josefin Sans, sans-serif', 
      fontSize: '3vh', 
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlock: '10px',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }" 
      @click="updateSwingiPoint('https://www.profitablecpmrate.com/ffumvexqt?key=0380ec4f99cfe042d93ffd7c6494635d')">
      <img src="/clickToSwing720x90.png" alt="Swingi logo" />
    </div>
    <div v-if="isMobile" :style="{
      width: '320px', 
      height: '50px', 
      cursor: 'pointer', 
      color: 'white', 
      fontFamily: 'Josefin Sans, sans-serif', 
      fontSize: '2vh', 
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlock: '10px',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }" 
      @click="updateSwingiPoint('https://www.profitablecpmrate.com/ffumvexqt?key=0380ec4f99cfe042d93ffd7c6494635d')">
      <img src="/clickToSwing320x50.png" alt="Swingi logo" />
    </div>

    <!-- Conteneur de la publicité
    <div v-if="!isMobile" id="ad-container" >
      <AdScript1 :connexion="isOnline" :conStat="statInfo"/>
    </div>
    <div v-if="isMobile" id="ad-container" >
      <AdScript1Mob :connexion="isOnline" :conStat="statInfo"/>
    </div>
  -->
    <div class="sm:px-32 flex flex-wrap justify-center">
      <UserCard v-for="userD in usersD" :key="userD.id" :userInfo="userD" />
    </div>




    
  


    <div v-if="instaName0.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
      --The 4 Swingi Lord--
    </div>
    
      <div v-if="!isMobile && instaName0.length > 0" :style="{width: '700px', border: '2px solid #ff5555', borderRadius: '5px', marginBlock: '10px', display: 'flex'}">
        <instaJob1 :instaName="instaName0" :instaImage="instaImage0" :instaToken="instaToken0"/>
      </div>
      <div v-if="isMobile && instaName0.length > 0" :style="{width: '350px', border: '2px solid #ff5555', borderRadius: '5px', marginBlock: '10px', display: 'flex'}">
        <instaJob1Mob :instaName="instaName0" :instaImage="instaImage0" :instaToken="instaToken0"/>
      </div>


    <div v-if="instaName10.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
      --Top 1 Ranked Swingi job--
    </div>
    
      <div v-if="!isMobile && instaName10.length > 0" >
        <instaJob1 :instaName="instaName10" :instaImage="instaImage10" :instaToken="instaToken10"/>
      </div>
      <div v-if="isMobile && instaName10.length > 0">
        <instaJob1Mob :instaName="instaName10" :instaImage="instaImage10" :instaToken="instaToken10"/>
      </div>

      <div v-if="instaName9.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 2 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName9.length > 0" >
        <instaJob1 :instaName="instaName9" :instaImage="instaImage9" :instaToken="instaToken9"/>
      </div>
      <div v-if="isMobile && instaName9.length > 0">
        <instaJob1Mob :instaName="instaName9" :instaImage="instaImage9" :instaToken="instaToken9"/>
      </div>

      <div v-if="instaName8.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 3 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName8.length > 0" >
        <instaJob1 :instaName="instaName8" :instaImage="instaImage8" :instaToken="instaToken8"/>
      </div>
      <div v-if="isMobile && instaName8.length > 0">
        <instaJob1Mob :instaName="instaName8" :instaImage="instaImage8" :instaToken="instaToken8"/>
      </div>

      <div v-if="instaName7.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 4 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName7.length > 0" >
        <instaJob1 :instaName="instaName7" :instaImage="instaImage7" :instaToken="instaToken7"/>
      </div>
      <div v-if="isMobile && instaName7.length > 0">
        <instaJob1Mob :instaName="instaName7" :instaImage="instaImage7" :instaToken="instaToken7"/>
      </div>

      <div v-if="instaName6.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 5 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName6.length > 0" >
        <instaJob1 :instaName="instaName6" :instaImage="instaImage6" :instaToken="instaToken6"/>
      </div>
      <div v-if="isMobile && instaName6.length > 0">
        <instaJob1Mob :instaName="instaName6" :instaImage="instaImage6" :instaToken="instaToken6"/>
      </div>

      <div v-if="instaName5.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 6 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName5.length > 0" >
        <instaJob1 :instaName="instaName5" :instaImage="instaImage5" :instaToken="instaToken5"/>
      </div>
      <div v-if="isMobile && instaName5.length > 0">
        <instaJob1Mob :instaName="instaName5" :instaImage="instaImage5" :instaToken="instaToken5"/>
      </div>

      <div v-if="instaName4.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 7 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName4.length > 0" >
        <instaJob1 :instaName="instaName4" :instaImage="instaImage4" :instaToken="instaToken4"/>
      </div>
      <div v-if="isMobile && instaName4.length > 0">
        <instaJob1Mob :instaName="instaName4" :instaImage="instaImage4" :instaToken="instaToken4"/>
      </div>

      <div v-if="instaName3.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 8 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName3.length > 0" >
        <instaJob1 :instaName="instaName3" :instaImage="instaImage3" :instaToken="instaToken3"/>
      </div>
      <div v-if="isMobile && instaName3.length > 0">
        <instaJob1Mob :instaName="instaName3" :instaImage="instaImage9" :instaToken="instaToken3"/>
      </div>

      <div v-if="instaName2.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 9 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName2.length > 0" >
        <instaJob1 :instaName="instaName2" :instaImage="instaImage2" :instaToken="instaToken2"/>
      </div>
      <div v-if="isMobile && instaName2.length > 0">
        <instaJob1Mob :instaName="instaName2" :instaImage="instaImage2" :instaToken="instaToken2"/>
      </div>

      <div v-if="instaName1.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 10 swingi job--
      </div>

      <div v-if="!isMobile && instaName1.length > 0" >
        <instaJob1 :instaName="instaName1" :instaImage="instaImage1" :instaToken="instaToken1"/>
      </div>
      <div v-if="isMobile  && instaName1.length > 0">
        <instaJob1Mob :instaName="instaName1" :instaImage="instaImage1" :instaToken="instaToken1"/>
      </div>
    

   
  </div>


</template>

<script>
import UserCard from "../components/UserCard.vue";
//import AdScript1 from '../components/AdsScript1.vue';
//import AdScript1Mob from '../components/AdsScript1Mob.vue';
import instaJob1 from '../components/instaJob/instaJob1.vue';
import instaJob1Mob from '../components/instaJob/instaJob1Mob.vue';
import { onMounted } from 'vue';

export default {
  metaInfo: {
    title: 'Swingi Job',
    meta: [
      { name: 'description', content: 'Do your daily job and earn Swingi Point' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme' }
    ]
  },
  name: "HomeFeed",
  components: {
    UserCard,
    //AdScript1,
    instaJob1,
    instaJob1Mob,
    //AdScript1Mob,
  },
  props: {

  },
  data() {
    return {
      usersD: [],
      adClickHandled: false, // Flag to check if the ad click has been handled
      sPoint: 0,
      isMobile: false,
      instaName0: [],
      instaToken0: [],
      instaImage0: [],
      instaName1: [],
      instaToken1: [],
      instaImage1: [],
      instaImage2: [],
      instaName2: [],
      instaToken2: [],
      instaName3: [],
      instaImage3: [],
      instaToken3: [],
      instaName4: [],
      instaImage4: [],
      instaToken4: [],
      instaName5: [],
      instaImage5: [],
      instaToken5: [],
      instaName6: [],
      instaImage6: [],
      instaToken6: [],
      instaName7: [],
      instaImage7: [],
      instaToken7: [],
      instaName8: [],
      instaImage8: [],
      instaToken8: [],
      instaName9: [],
      instaImage9: [],
      instaToken9: [],
      instaName10: [],
      instaImage10: [],
      instaToken10: [],

      isOnline: navigator.onLine, // Vérifie si l'utilisateur est connecté au chargement
      statInfo: 'Pleas Add Swingi.pro To Your Ads Blocker White Liste, & Earn More Swingi Point.',
    };
  },
  setup() {
    // Appeler la fonction de création de l'overlay après le montage du composant
    onMounted(() => {
      if (window.createLemonSqueezy) {
        window.createLemonSqueezy(); // Initialiser l'overlay Lemon Squeezy
      } else {
        console.error("Lemon.js script not loaded");
      }
    });
  },
  created(){

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    
  },
  mounted() {

    this.updateOnlineStatus();

    console.log('con: ', this.isOnline, this.statInfo);

    if (this.isOnline) {
          this.statInfo = 'Pleas Add Swingi.pro To Your Ads Blocker White Liste, & Earn More Swingi Point.';
        } else {
          this.statInfo = 'Connexion Out, Pleas Reconnect';
        }
        // Détecter la taille de l'écran
    this.checkScreenSize();
    
    window.addEventListener('resize', this.checkScreenSize);

    this.getInstaJob();
    
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },



  methods: {
    
    async updateSwingiPoint(url) {
      if (localStorage.getItem('authToken')) {
        try {
          var token = localStorage.getItem('authToken');

          const response = await fetch(`https://swingi.pro/api.php?action=updateBankValue`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
          });

          if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
          }

          const data = await response.json();

          if (!data.success) {
            console.error('Error:', data.message);
          } else {
            
            this.$emit('newSPoint', data.newSPoint);
            window.open(url, '_blank'); // '_blank' opens the link in a new tab
          }

        } catch (error) {
          console.error('Error during fetch:', error);
        }
      } else {
        this.$router.push({ name: 'UserD', params: { id: 0 } });
      }
      
    },


    updateOnlineStatus() {
      // Met à jour l'état en fonction de la connexion
      this.isOnline = navigator.onLine;
    },


    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
    },


    async getInstaJob() {
    try {

      
      const response = await fetch('https://swingi.pro/api.php?action=getInstaInfo', {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const response2 = await fetch('https://swingi.pro/api.php?action=getInstaJob', {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const response3 = await fetch('https://swingi.pro/api.php?action=getBankDetails', {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok && !response2.ok ) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const data2 = await response2.json();
      const data3 = await response3.json();

      console.log(data3.data);

      if (data.error) {
        console.error('Erreur:', data.error, 'Détails:', data.details);
      } else {
      
      if (data.success === false){
        
        console.error('this is instaQst1:', data);
      } else {

        const actifInstaToken = [];

        for (var r = 0; r < data3.data.length; r++){
          console.log('data2 recieved: ',data2);
          const index = data2.data.tokens.findIndex(token => token === data3.data[r].token);
          if(index != -1) {
            
            actifInstaToken.push(data3.data[r]);
          }
        }
        

        // Combiner les tokens et les points en un seul tableau d'objets
        const combined = actifInstaToken.map(item => {
          return { token: item.token, sPoint: item.sPoint };
        });


        // Trier le tableau combiné en fonction des points
        const sortedCombined = combined.sort((a, b) => b.sPoint - a.sPoint);

        // Extraire les tokens et points triés
        //const sortedPoints = sortedCombined.map(item => item.sPoint);
        const sortedToken = sortedCombined.map(item => item.token);

        // Prendre les 4 plus grandes valeurs
        const top4Token = sortedToken.slice(0, 4);

        console.log('data: ', top4Token);

        for (var i = 0; i < 4; i++) {
          const index = data.data.data.findIndex(item => item.token === top4Token[i]);
              
          // Vérifier que l'index est valide avant de pousser dans les tableaux
          if (index !== -1) {
            this.instaName0.push(data.data.data[index].instaUser);
            this.instaImage0.push(data.data.data[index].instaImage);
            this.instaToken0.push(data.data.data[index].token);
          }
        }


        console.log('sorted: ', this.instaName0);


          
        for(var v = 0; v < data.data.data.length; v++){
          const foundIndex = data2.data.tokens.findIndex(token => token === data.data.data[v].token);
          const foundItem = data3.data.find(item => item.token === data.data.data[v].token);       
          
          
            if (foundIndex !== -1) {
              if (foundItem.sPoint >= 0 && foundItem.sPoint <= 5000){
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName1.push(data.data.data[v].instaUser);
                  this.instaImage1.push(data.data.data[v].instaImage);
                  this.instaToken1.push(data.data.data[v].token);

                }
              } else if (foundItem.sPoint > 5000 && foundItem.sPoint <= 10000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName2.push(data.data.data[v].instaUser);
                  this.instaImage2.push(data.data.data[v].instaImage);
                  this.instaToken2.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 10000 && foundItem.sPoint <= 20000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName3.push(data.data.data[v].instaUser);
                  this.instaImage3.push(data.data.data[v].instaImage);
                  this.instaToken3.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 20000 && foundItem.sPoint <= 50000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName4.push(data.data.data[v].instaUser);
                  this.instaImage4.push(data.data.data[v].instaImage);
                  this.instaToken4.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 50000 && foundItem.sPoint <= 100000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName5.push(data.data.data[v].instaUser);
                  this.instaImage5.push(data.data.data[v].instaImage);
                  this.instaToken5.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 100000 && foundItem.sPoint <= 200000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName6.push(data.data.data[v].instaUser);
                  this.instaImage6.push(data.data.data[v].instaImage);
                  this.instaToken6.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 200000 && foundItem.sPoint <= 400000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName7.push(data.data.data[v].instaUser);
                  this.instaImage7.push(data.data.data[v].instaImage);
                  this.instaToken7.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 400000 && foundItem.sPoint <= 800000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName8.push(data.data.data[v].instaUser);
                  this.instaImage8.push(data.data.data[v].instaImage);
                  this.instaToken8.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 800000 && foundItem.sPoint <= 1000000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName9.push(data.data.data[v].instaUser);
                  this.instaImage9.push(data.data.data[v].instaImage);
                  this.instaToken9.push(data.data.data[v].token);
                }
              } else if (foundItem.sPoint > 1000000) {
                if(data2.data.activ[foundIndex] === '1') {

                  this.instaName10.push(data.data.data[v].instaUser);
                  this.instaImage10.push(data.data.data[v].instaImage);
                  this.instaToken10.push(data.data.data[v].token);
                }
              } 
                
              
          }

          
          
        }
        

        
        
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }

  console.log('foundItem: ',this.instaName1);
},


  },
};
</script>

<style>
#ad-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
}

/* Style personnalisé pour le bouton */
.lemonsqueezy-button {
  width: 200px;
  background-color: #f4c152;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lemonsqueezy-button:hover {
  background-color: #e0ac3d;
}

.bg1 {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}







</style>
