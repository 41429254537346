<template>
    <div class="body4">
       <div class="instaBox2">
        <div class="viewStatBox">
          <p class="statTitle">
            View Statistics
          </p>

            <p class="instaTitle">
              Instagram
            </p>
            <div class="structure-box">
              <CheckBox label="" v-model="isChecked" @input="updateInstaValue"/>
            </div>

          
        </div>
        <div class="instaViewBox">
          <div class ="instaImage">
            
            <img :src="instaImage" alt="Profile Picture" class="profileImage">
          </div>
          <div class ="instaName">

            <p class="userTitle">
              {{this.instaUser}}
            </p>

          </div>

        </div>
        

       </div>
       <div class="row-btn">
        <div class="boxBtn4">
          <button class="btn4" @click="changeInsta">
              Change
          </button>
      </div>
      <div class="boxBtn4">
          <button class="btn4" @click="editInsta">
              Edit
          </button>
      </div>
       </div>
       

    </div>
</template>



<script>

import CheckBox from "../components/CheckBox.vue";

export default {
    name: "InstagramCardInfo",

    components: {
    CheckBox,
    
  },



    data() {
    return {
      instaUser: localStorage.getItem('instaUser'),
      instaImage: localStorage.getItem('instaLinkHD'),
      isInstaEdit: false,
      isInstaChange: false,
      isInstaConfirmed: false,
      token: localStorage.getItem('authToken'),
      isChecked: false,
      instaQst: [],
      
    };
  },
  created() {
    this.addInstaQst();
  },
  methods: {
    


    editInsta(){
      this.isInstaEdit = true,
      this.isInstaChange = false,
      this.$emit('instagram-edit');
    },

    changeInsta(){
      this.isInstaEdit = false,
      this.isInstaChange = true,
      this.$emit('instagram-change');
    },

    async updateInstaValue(value) {
      
      this.isChecked = value;
      var checkBox = 0;
      if(this.isChecked === true) {
        checkBox = 1;
      } else {
        checkBox = 0;
      }
      const updateData = {
        token: this.token,
        field: 'activ',
        value: checkBox,// Assignez l'URL de l'image téléchargée
      };


      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateInstaDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          console.log('Insta is :', checkBox);
          localStorage.setItem('insta-Active', this.isChecked);
        } else {
          console.error('Error updating insta Value:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async addInstaQst() {

  try {
    
    const response = await fetch(`https://swingi.pro/api.php?action=getInstaQst&token=${encodeURIComponent(this.token)}`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Réponse POST:', data);

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      this.instaQst = data.data;
      if (this.instaQst[7] == 0){
            localStorage.setItem('insta-Active', false);
            
            this.isChecked = false;

          } else if (this.instaQst[7] == 1) {
            localStorage.setItem('insta-Active', true);

            this.isChecked = true;

          }

          console.log('this is instaQst:', this.isChecked);
          
    }

    this.canGo = true;
  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
}
  },
}




</script>

<style>

.body4 {
  margin-block: 40px;
  width: 90%; /* Prendre toute la largeur du parent */
  border-radius: 6px;
  background: #ffffffcc;
  border: 1px solid rgba(255, 255, 255, .8);
  box-shadow: 0 0 10px #00000033;
    

  }

  .statTitle {
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    color: #7fadc7;
    font-weight: bold;
  }

  .userTitle {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre le texte verticalement */
    justify-content: center; /* Centre le texte horizontalement */
    text-align: center; /* Centrer le texte horizontalement à l'intérieur de l'élément */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    height: 100%; /* Assurez-vous que le conteneur a une hauteur définie */

  }

  .structure-box {
    margin-bottom: 25px;
    
  }


  .profileImage{
    height: 40px;
    width: 40px;
    border-radius: 50%; /* Rend l'image circulaire */
  }

  .viewStatBox {
    margin: 5px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  .instaViewBox {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre verticalement les éléments dans le conteneur */
    margin: 5px;
    height: 40px;
    background-color: #e3f5ff;
  }

  .instaImage{
    margin-right: 5px;
    height: 40px;
    width: 40px;

  }
  .instaName{
    margin-right: 5px;
    height: 40px;
    width: 90%;

  }

  .instaBox2 {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 75px;
    width: 100%;
    background-color: #cbecff;
  }



  .text3 {
    width: 60%;
    font-size: 10px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }


  .row-btn {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre verticalement les éléments dans le conteneur */
    justify-content: space-between; /* Espace les éléments horizontalement avec de l'espace égal entre eux */
    width: 100%; /* Prendre toute la largeur du parent */

    

  }




  .boxBtn4 {
    width: 50%;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .btn4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn3:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


</style>