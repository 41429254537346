import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import "./assets/styles/tailwind.css";
import { createMetaManager } from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
import { faGift, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faGift, faCheck, faTimes);
library.add(faAccessibleIcon);

// Créer l'application
const app = createApp(App);

// Utiliser le routeur
app.use(router);

// Utiliser vue-meta pour la gestion des balises méta
app.use(createMetaManager());

// Enregistrer le composant Font Awesome
app.component('font-awesome-icon', FontAwesomeIcon);

// Monter l'application
app.mount('#app');
