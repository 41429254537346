<template>
    <div v-if="verificationCode" class="checking-box">
        <div v-if="isChecking" >
            Vérification in proccesse
            <Loading :width="'50px'"/>
        </div> 
        <div v-else>
            <div>
                {{ verificationCode }}
            </div>
            <div>
                {{ log }}
            </div>
            <form @submit.prevent="btnClick">
                <input v-model="newEmail" placeholder="Vérify with another Email ?">

                <div class="g-recaptcha " @click="sendConfirmationEmail">
                    Send
                  </div>
            </form>
        </div>
    </div>
    <div v-else>
        <div>
            {{ log }}
        </div>
        <div>
            <form @submit.prevent="btnClick">
                <input v-model="newEmail"/>
                <div class="g-recaptcha " @click="sendConfirmationEmail">
                    Send
                  </div>
            </form>
            
        </div>
        
    </div>

</template>

<script>
    import { appCheck } from '../firebase';
    import Loading from '../components/items/loading.vue';
    

export default {
  metaInfo: {
    title: 'Email Verification',
    meta: [
      { name: 'description', content: 'Validate your confirmation code' },
      { name: 'keywords', content: 'vue, SEO, web development' }
    ]
  },
    name: 'EmailVerification',
    components: {
      Loading,
  },
  data() {
    return {
      email: localStorage.getItem('userEmail'), // Stockez l'email ici, éventuellement récupéré d'un stockage local
      newEmail: '',
      isChecking: false,
      log: '',
      verificationCode: '',
    };
  },

  created() {
    // Récupérer le code à partir de l'URL
    this.verificationCode = this.$route.params.code;
    this.verEmail();
    // Vous pouvez ensuite utiliser verificationCode pour des opérations ultérieures
  },
  
  
  methods: {

    async generateRecaptchaToken(token) {
          if (appCheck) {
            console.log("App Check initialized: ", appCheck);
            try {
              const tokenResponse = token; // Appelle getToken sans passer de paramètre
              console.log('reCaptcha Token:', tokenResponse);
              return token; // Retourne le token
            } catch (error) {
              console.error("Erreur lors de la récupération du token reCAPTCHA :", error);
              throw error; // Lance l'erreur pour la gérer ailleurs
            }
          } else {
            console.error("App Check not initialized.");
          }
        // Récupérer le token reCAPTCHA
        
      },


        
        async sendConfirmationEmail(reCaptcha) {

          const currentTime = Date.now(); // Le temps actuel en millisecondes

          // Vérifiez si une date de vérification existe dans le localStorage
          const lastAuthTime = localStorage.getItem('authTimeVérification');

          if (lastAuthTime) {
            // Convertir la valeur stockée en nombre (timestamp)
            const lastTime = parseInt(lastAuthTime, 10);

            // Calculer la différence de temps en secondes
            const timeDifference = (currentTime - lastTime) / 1000; // Différence en secondes

            if (timeDifference < 10) {
              this.log = `Pleas wait ${10 - Math.floor(timeDifference)} s.`;
              this.isChecking = false;
            } else {
              // Mettre à jour le temps de vérification à l'heure actuelle
              localStorage.setItem('authTimeVérification', currentTime.toString());

              const token = await this.generateRecaptchaToken(reCaptcha); // Appel correct de la méthode

              const sendInfo = {
                email: this.newEmail,
                recaptchaToken: token,

              }

              if (token) {
                try {
                  const response = await fetch('https://swingi.pro/api.php?action=sendEmail', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sendInfo)
                  });

                  //const result = await response.json();
                  console.log('response test: ', response);
                } catch (error) {
                  console.error(error);
                }
                
              } else {
                console.log('No reCaptcha Token found');
              }
              this.log = 'New vérification code send';
            }
            
          } else {
            // Si aucune vérification précédente, stocker l'heure actuelle
            localStorage.setItem('authTimeVérification', currentTime.toString());

            const token = await this.generateRecaptchaToken(reCaptcha); // Appel correct de la méthode

            const sendInfo = {
              email: this.newEmail,
              recaptchaToken: token,

            }

            if (token) {
              try {
                const response = await fetch('https://swingi.pro/api.php?action=sendEmail', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(sendInfo)
                });

                //const result = await response.json();
                console.log('response test: ', response);
              } catch (error) {
                console.error(error);
              }
              
            } else {
              console.log('No reCaptcha Token found');
            }
          }

          this.isChecking = false;
          

          

          


        },

        async verEmail() {
            this.isChecking = true;
            if(this.verificationCode) {
              const verifyInfo = {
                token: this.verificationCode,

              };
              try {
                    const response = await fetch('https://swingi.pro/api.php?action=verifyEmail', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(verifyInfo)
                    });

                    if (response.ok) {
                          //const data = await response.json(); // Récupération des données JSON
                          this.log = "Account verified. You will be redirected shortly, please proceed to login.";
                          setTimeout(() => {
                            this.$router.push({ name: 'UserD', params: { id: '0' } });
                            this.isChecking = false;
                          }, 2000);
                      } else {
                          console.error('HTTP error', response.status); // Affichage d'une erreur HTTP
                          this.log = 'We can\'t vérify this email, vérify another email ?';
                          this.isChecking = false;
                      }

                    
                  } catch (error) {
                    console.error(error);
                    this.isChecking = false;
                  }
              } else {
                this.log = 'Vérification code not found. send a new code ?';
                this.isChecking = false;
              }
            
              }
            }
          };

</script>

<style>
    .checking-box {
        margin-top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
</style>