<template>
    
    <div :style="{
        marginLeft: '5%',
        width: '90%',
        minWidth: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
        flexDirection: 'column',
        borderRadius: '6px',
        background: '#ffffffcc',
        border: '1px solid rgba(255, 255, 255, .8)',
        boxShadow: '0 0 10px #00000033'

    }">
        <div :style="{
            width: '300px',
            height: '50px',
            minWidth: '300px',
            backgroundColor: '#00A3FF80',
            display: 'flex',
            alignItems: 'center'

        }">
            <div>
                <img :style="{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    objectFit: 'cover',
                    marginInline: '5px',
                }" :src="imageUrl ? imageUrl: '/g.png'" alt="Thumbnail"/>
            </div>

            <div :class="{
                  
                'text-xs': true,
                'max-w-250px': true,
                'overflow-hidden': true,
                'text-ellipsis': true,
                'whitespace-nowrap': true,
                
              }" :style="{fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
                {{ instaName }}
            </div>
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',
            

        }">
            discription :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '30px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px'

        }">
            {{ discription }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Question :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '30px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px'

        }">
            {{ qst }} ?
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 1 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',

        }" class="hoverable" @click="validateQst(answer1)">
            {{ answer1 }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 2 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',

        }" class="hoverable" @click="validateQst(answer2)">
            {{ answer2 }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 3 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',
        }" class="hoverable" @click="validateQst(answer3)">
            {{ answer3 }}.
        </div>

        

    </div>

    <div v-if="isFinished" class="back-box">
        <div class="info-box">
            <div :style="{
                width: '100px',
                marginTop: '10px',
                minWidth: '100px',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
            }">
                {{ finishingMsg }}
            </div>

            <div :style="{
                width: '180px',
                marginTop: '10px',
                minWidth: '180px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
                cursor: 'pointer',
            }" class="hoverable2" @click="isFinished = false">
                OK
            </div>

          
        </div>
      </div>

</template>



<script>

export default {
    metaInfo: {
    title: 'Instagrame Qst',
    meta: [
      { name: 'description', content: 'Answer Qst and earn money when you win' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme, answer' }
    ]
  },
    name: "InstaQst",

    data () {
        return {
            token: '',
            imageUrl: '',
            instaName: '',
            swingiPoint: 0,
            qst: '',
            answer1: '',
            answer2: '',
            answer3: '',
            discription: '',
            country: '',
            active: true,
            isFinished: false,
            finishingMsg: '',
            isManaging: false

        }
    },

    mounted() {
        this.token = this.$route.params.id;  // Récupérer le paramètre de l'URL

        this.getQst();

    },

    methods: {
        async validateQst(answer) {
            this.isManaging = true;
            const userDetails = {
                token: localStorage.getItem('authToken'),
                tokenJob: this.token,
                answer: answer,
                type: "insta",
            }
            try {
                console.log(userDetails);
                const response = await fetch('https://swingi.pro/api.php?action=jobManager', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userDetails)
                })

                if (response.ok) {
                    const data = await response.json();
                    
                    this.finishingMsg = data.message;
                    this.isFinished = true;
                    
                    console.log('Response Post: ',data.message);
                } else {
                    const data = await response.json();
                    
                    this.finishingMsg = data.message;
                    this.isFinished = true;
                    console.error('error: ',data.message);
                }
            } catch (e) {
                console.error('error fetch: ',e);
            }
                
            this.isManaging = false;
            
        },
        async getQst() {
            try{
                const response = await fetch(`https://swingi.pro/api.php?action=getInstaQst&token=${encodeURIComponent(this.token)}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response2 = await fetch(`https://swingi.pro/api.php?action=getInstaUser&token=${encodeURIComponent(this.token)}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response3 = await fetch(`https://swingi.pro/api.php?action=getBankUser&token=${encodeURIComponent(this.token)}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                if (response.ok) {
                    const data = await response.json();
                    this.qst = data.data[2];
                    this.discription = data.data[1];
                    this.answer1 = data.data[3];
                    this.answer2 = data.data[4];
                    this.answer3 = data.data[5];
                    this.country = data.data[6];
                    if (data.data[7] !== 1) {
                        this.active = false;
                    }
                    console.log('Response: ',this.qst);
                }

                if (response2.ok) {
                    const data = await response2.json();
                    this.imageUrl = data.data.instaImage;
                    this.instaName = data.data.instaUser;
                    console.log('Response1: ',this.imageUrl, ' ', this.instaName);
                }

                if (response3.ok) {
                    const data = await response3.json();
                    this.swingiPoint = data.data.sPoint;
                    console.log('Response2: ',this.swingiPoint);
                }

            } catch (e) {
                console.log(e);
            }
        }
    }
}



</script>


<style scoped>
.info-box {
    position: fixed;
    width: 200px;
    height: 200px;
    min-width: 200px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 25%);
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    display: flex;
    flex-direction: column; /* Assure que les éléments sont alignés verticalement */
    align-items: center;
  
  }

  .back-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(90, 90, 90, 0.5);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */

    z-index: 50; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
  
  }

.hoverable {
  transition: background-color 0.3s ease; /* Optionnel : pour une transition douce */
}

.hoverable:hover {
  background-color: #ccc;
}

.hoverable2 {
    transition: background-color 0.3s ease; /* Optionnel : pour une transition douce */
  }
  
  .hoverable2:hover {
    background-color: #bdff8e;
  }
</style>

