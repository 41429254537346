<template>

  <div class="sBox">
    <div class="pBox1">
      
        <div class="box1" @click="instaClick(instaToken[0])">
          <div class="pImage">
              <img class="shortImage" :src="instaImage[0] ? instaImage[0]: '/g.png'" alt="Thumbnail"/>
          </div>
          <div :class="{
                
            'text-xs': true,
            'max-w-250px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
            }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{instaName[0] ? instaName[0]: 'No Job Found'}}
          </div>
        </div>
      <div class="box1" @click="instaClick(instaToken[1])">
          <div class="pImage">
              <img class="shortImage" :src="instaImage[1] ? instaImage[1]: '/g.png'" alt="Thumbnail"/>
          </div>
          <div :class="{
                
            'text-xs': true,
            'max-w-250px': true,
            'overflow-hidden': true,
            'text-ellipsis': true,
            'whitespace-nowrap': true,
          }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
            {{instaName[1] ? instaName[1]: 'No Job Found'}}
          </div>
      </div>
    </div>
  <div class="pBox2">
    
      <div class="box1" @click="instaClick(instaToken[2])">
        <div class="pImage">
            <img class="shortImage" :src="instaImage[2] ? instaImage[2]: '/g.png'" alt="Thumbnail"/>
        </div>
        <div :class="{
              
          'text-xs': true,
          'max-w-250px': true,
          'overflow-hidden': true,
          'text-ellipsis': true,
          'whitespace-nowrap': true,
        }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
          {{instaName[2] ? instaName[2]: 'No Job Found'}}
        </div>
    </div>
    <div class="box1" @click="instaClick(instaToken[3])">
        <div class="pImage">
            <img class="shortImage" :src="instaImage[3] ? instaImage[3]: '/g.png'" alt="Thumbnail"/>
        </div>
        <div :class="{
              
          'text-xs': true,
          'max-w-250px': true,
          'overflow-hidden': true,
          'text-ellipsis': true,
          'whitespace-nowrap': true,
        }" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '14px' }">
          {{instaName[3] ? instaName[3]: 'No Job Found'}}
        </div>
    </div>
    
      
      </div>
    
    </div>

  </template>
  
  <script>
  export default {
    name: "instaJob1",
    props: {
    instaName: {
        type: Array,
    required: false,
    validator(value) {
      // Vérifier que chaque élément du tableau est une chaîne de caractères
      return value.every(item => typeof item === 'string');
    }
    },
    instaImage: {
        type: Array,
    required: false,
    validator(value) {
      // Vérifier que chaque élément du tableau est une chaîne de caractères
      return value.every(item => typeof item === 'string');
    }
    },
    instaToken: {
        type: Array,
    required: false,
    validator(value) {
      // Vérifier que chaque élément du tableau est une chaîne de caractères
      return value.every(item => typeof item === 'string');
    }
    },
    
  },
    data() {
      return {
        PBox1: '',
        PBox2: '',
        PBox3: '',
        PBox4: '',
      };
    },

    created() {

  
  
    },
    methods: {
    
      instaClick (instaToken) {
        this.$router.push({
          name: 'InstaQst',
          params: { id: instaToken }  // Transmettre les paramètres ici
        });
      }
  
  
    }
  };
  </script>
  
  <style scoped>

  .pBox1 {
    width: 300px;
    
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 20px;
 
    
  }
  .pBox2 {
    width: 300px;
    
    display: flex;
    flex-direction: column;
    align-items: right;
    margin: 20px;

    
  }

  .sBox {
    width: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline : 10%;
    

  }
  .box1 {
    width: 300px;
    height: 50px;
    min-width: 300px;
    background-color: #00A3FF80;
    margin-block: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .pImage {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: white;
    border-radius: 50%;
    margin-inline: 5px;
  }

  .shortImage {
    border-radius: 50%;
    object-fit: cover;
  }

  </style>
  