<template>
  <div v-if="!isCheckout" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '150px', fontFamily: 'Josefin Sans, sans-serif'}">
    <div :style="{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginInline: '20px'}">
      <div :style="{height: '30px', 
        width: '150px', 
        marginBlock: '10px',
        backgroundColor: 'black',
        color: 'white',
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        alignItems: 'center',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
        }">

      Limited Offer

      </div>
      <div :style="{height: '230px', 
        width: '150px', 
        backgroundImage: 'url(https://swingi.pro/welcome.png)', 
        backgroundSize: 'cover', 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        alignItems: 'center',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
        cursor: 'pointer'}" @click="createCheckout(true)">
            <div :style="{height: '100px', width:'100px'}">
                <svg fill="#ff5555" width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
            </div>

            <div :style="{color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center', fontSize: '1.8vh'}">
              {{ welcomeDesc }}
            </div>
  
            <div :style="{color: '#ff5555', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center', fontSize: '3vh', fontWeight: 'bold'}">
                {{ welcomeCount }}
            </div>
  
            <div :style="{color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center', fontSize: '2.5vh'}">
              {{ welcomePrice }} $
            </div>

            <div :style="{color: 'black', marginTop: '3vh', fontWeight: 'bold'}">
                BUY NOW
            </div>
            <!--
            <div v-if="!isLoading" class="btn" @click="checkout(false, 'https://swingi.lemonsqueezy.com/buy/1887ea11-2b83-4372-a18b-1cb3e5d0ff49?embed=1', 375581, welcomeCount)">
              BUY NOW
          </div>
          -->
  
        </div>
    </div>
      <div :style="{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginInline: '20px'}">
        
        <div :style="{height: '30px', 
        width: '150px', 
        backgroundColor: 'black',
        marginBlock: '10px',
        color: 'white',
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        alignItems: 'center',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, .5)'}">

        Market

      </div>
        <div :style="{height: '230px', 
          width: '150px', 
          backgroundImage: 'linear-gradient(to bottom, rgb(255, 160, 0), rgb(255, 196, 114))', 
          display: 'flex', 
          justifyContent: 'center', 
          flexDirection: 'column', 
          alignItems: 'center',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
          cursor: 'pointer'}" @click="createCheckout(false)">
              <div :style="{height: '100px', width:'100px'}">
                  <svg fill="#ff5555" width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
      
                      <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                      
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                      
                      <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                      
                      </svg>
              </div>

              <div :style="{color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign:'center', fontSize: '1.8vh'}">
                {{ swingiStock }} in stock
              </div>

              <div :style="{color: '#ff5555', fontWeight: 'bold', fontSize: '3vh'}">
                1
            </div>

  
              <div :style="{color: 'black', fontSize: '2.5vh'}">
                  {{ swingiPrice }} $
              </div>

              
              <div v-if="isLoading" class="btn">
                <Loading type="submit" :width="'40px'"/>
              </div>

              

              <div :style="{color: 'black', marginTop: '3vh', fontWeight: 'bold'}">
                BUY NOW
            </div>

              <!--
              <div v-if="!isLoading && !isWising" class="btn" @click="checkout(true, '', 368618, count)">
                BUY WITH OTHER METHODS
            </div>
            <div v-if="isLoading" class="btn">
              <Loading type="submit" :width="'40px'"/>
            </div>

          -->
          
  
          </div>
          
          
  
          
      </div>
      
  </div>
  
  <div v-else-if="isCheckout && !isPromo" :style="{display: 'flex', alignItems: 'center', marginTop: '150px', flexDirection: 'column', fontFamily: 'Josefin Sans, sans-serif'}">
    <div v-if="!isLoading && !isWising" class="btn2" @click="isCheckout = false">
      RETURN
    </div>

    <div :style="{height: '100px', width:'100px'}">
      <svg fill="#ff5555" width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">

          <g id="SVGRepo_bgCarrier" stroke-width="0"/>
          
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
          
          <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
          
          </svg>
    </div>

    <div :style="{color: '#ff5555', fontWeight: 'bold', fontSize: '3vh'}">
      Swingi Point
    </div>

    <div :style="{}">
      <input
      type="number"
      ref="autoResizeInput"
      step="1" min="100"
      v-model="count"
      @input="autoResizeInput"
      />
    </div>

    
    <div :style="{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}">
      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', backgroundColor: 'black', color: 'white'}">
        <div :style="{width: '25%'}">
          Name
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          Price
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          Qty
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          Total USD
        </div>
      </div>
      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between', backgroundColor: '#ff555520', color: 'black'}">
        <div :style="{width: '25%'}">
          Swingi Point
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          {{swingiPrice}}
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          x{{count}}
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{swingiPrice * count}} USD
        </div>
      </div>

      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between'}">
        <div :style="{width: '25%'}">
          TAX
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          {{  tax }}
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          x1
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{  tax }} USD
        </div>
      </div>

      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2.5vh', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', backgroundColor: '#ff555520', color: 'black'}">
        <div :style="{width: '75%'}">
          TOTAL
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{ totalPrice }} USD
        </div>
      </div>

      <div v-if="price < 0.01" :style="{color: 'red', fontSize: '2vh', fontWeight: 'bold'}">
        {{ logPrice }}
      </div>
  
      <div v-else-if="isPaypal && price >= 0.01" :style="{width: '100%', maxWidth: '750px', marginBlock: '20px'}">
        <div id="paypal-button-container"></div>
      </div>
    </div>


    <!--
    <div v-if="!isLoading && !isWising" class="btn">
      VISA PAY
    </div>
    <div v-if="!isLoading && !isWising" :style="{display: 'flex', justifyContent: 'center', fontSize: '1vh', color: '#ff555590', fontWeight: 'bold'}">
      COMING SOON !
    </div>
    <div v-if="isLoading" class="btn">
      <Loading type="submit" :width="'40px'"/>
    </div>
    -->
  </div>
  <div v-else-if="isCheckout && isPromo" :style="{display: 'flex', alignItems: 'center', marginTop: '150px', flexDirection: 'column', fontFamily: 'Josefin Sans, sans-serif'}">
    <div v-if="!isLoading && !isWising" class="btn2" @click="isCheckout = false">
      RETURN
    </div>

    <div :style="{height: '100px', width:'100px'}">
      <svg fill="#ff5555" width="100px" height="100px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">

          <g id="SVGRepo_bgCarrier" stroke-width="0"/>
          
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
          
          <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
          
          </svg>
    </div>

    <div :style="{color: '#ff5555', fontWeight: 'bold', fontSize: '3vh'}">
      Swingi Point
    </div>

    <div :style="{color: '#ff5555', fontWeight: 'bold', fontSize: '3vh'}">
      {{ welcomeCount }}
    </div>

    
    <div :style="{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}">
      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', backgroundColor: 'black', color: 'white'}">
        <div :style="{width: '25%'}">
          Name
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          Price
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          Qty
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          Total USD
        </div>
      </div>
      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between', backgroundColor: '#ff555520', color: 'black'}">
        <div :style="{width: '25%'}">
          Swingi Point
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          {{welcomePrice}}
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          x1
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{welcomePrice}} USD
        </div>
      </div>

      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2vh', display: 'flex', justifyContent: 'space-between'}">
        <div :style="{width: '25%'}">
          TAX
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          {{  tax }}
        </div>
        <div :style="{width: '25%', textAlign: 'center'}">
          x1
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{  tax }} USD
        </div>
      </div>

      <div :style="{width: '100%', marginBlock: '2px', paddingBlock: '10px', fontSize: '2.5vh', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', backgroundColor: '#ff555520', color: 'black'}">
        <div :style="{width: '75%'}">
          TOTAL
        </div>
        <div :style="{width: '25%', textAlign: 'right'}">
          {{ totalPrice }} USD
        </div>
      </div>

      <div v-if="price < 0.01" :style="{color: 'red', fontSize: '2vh', fontWeight: 'bold'}">
        {{ logPrice }}
      </div>
  
      <div v-else-if="isPaypal && price >= 0.01" :style="{width: '100%', maxWidth: '750px', marginBlock: '20px'}">
        <div id="paypal-button-container"></div>
      </div>
    </div>
  </div>





  

  </template>
  
  <script>
  import Loading from '../components/items/loading.vue'
  import { v4 as uuidv4 } from 'uuid';

  

  export default {
    metaInfo: {
    title: 'Swingi Store',
    meta: [
      { name: 'description', content: 'Buy Swingi Point & more' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme, answer, swingi, point' }
    ]
  },

    name: "StoreTable",
    components: {
      Loading,
    },
    data() {
      return {
        welcomeCount: 10000,
        welcomePrice: 40,
        welcomeDesc: 'Valid until 01-06-2025',
        swingiPrice: 0.005,
        price: 0.005 * 100,
        totalPrice: 1.005,
        tax: 0,
        count: 100,
        isLoading: false,
        amount: 0,
        currency: 'USD',
        wiseMethod: false,
        isWising: false,
        quoteId: '',
        sourceAmount: '',
        sourceCurrency: '',
        targetCurrency: '',
        targetAccount: '',
        transactionID: '',
        isPaypal: true,
        isCheckout: false,
        logPrice: '',
        isPromo: false,
        swingiStock: 1000000,
      };
    },

    mounted() {
    // Assurez-vous que PayPal est chargé et accessible
    
    if (this.price < 0.01) {
        this.logPrice = 'Make sur the price are bigger or equal then 0.01 $'
      }

      
    
  },


    methods: {

    createCheckout(isPromo) {
      this.isCheckout = true;
      if (isPromo) {
        this.isPromo = isPromo;
        this.totalPrice = this.welcomePrice + this.tax;
        const checkInterval = setInterval(() => {

        const paypalContainer = document.querySelector('#paypal-button-container');
        if (paypalContainer) {
          paypalContainer.innerHTML = ''; // Clear the container contents, effectively removing the button
        }
        if (paypalContainer) {
        clearInterval(checkInterval); // Arrêter l'intervalle une fois que l'élément est trouvé
        this.initializePayPalButtons();
        }
      }, 100); // Vérifie toutes les 100ms
      }

      if (this.price < 0.01 && !isPromo) {
        this.isPromo = isPromo;
        this.logPrice = 'Make sur the price are bigger or equal then 0.01 $'
      } else if (!isPromo) {
        
        this.isPromo = isPromo;
        const checkInterval = setInterval(() => {
          const paypalContainer = document.querySelector('#paypal-button-container');
            if (paypalContainer) {
              paypalContainer.innerHTML = ''; // Clear the container contents, effectively removing the button
            }
          if (paypalContainer) {
            clearInterval(checkInterval); // Arrêter l'intervalle une fois que l'élément est trouvé
            this.initializePayPalButtons();
          }
        }, 100); // Vérifie toutes les 100ms
      }
        
      },

      initializePayPalButtons() {
        if (window.paypal) {
          window.paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: this.totalPrice // Montant de la transaction
                  }
                }],
                application_context: {
                  shipping_preference: 'NO_SHIPPING' // Désactiver la demande d'adresse de facturation
                }
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then(details => {
                const transactionAmount = details.purchase_units[0].amount.value;
                const transactionId = details.id;

                // Log message with transaction success, amount, and transaction ID
                console.log(`Transaction réussie: ${transactionAmount} € avec l'ID de transaction ${transactionId}`);
                
                alert('Transaction effectuée par ' + details.payer.name.given_name);
              });
            }
          }).render('#paypal-button-container');
        } else {
          console.error("PayPal SDK non chargé");
        }
      },

    autoResizeInput() {

      this.price = parseFloat((this.swingiPrice * this.count).toFixed(2));
      this.totalPrice = this.price + this.tax;
        // Remove the PayPal button container from the DOM
      const buttonContainer = document.querySelector('#paypal-button-container');
      if (buttonContainer) {
        buttonContainer.innerHTML = ''; // Clear the container contents, effectively removing the button
      }
      this.createCheckout(false);
    },

    async getRecipient() {

      try {
          const response = await fetch('https://swingi.pro/api.php?action=wiseRecipient', {
            method: 'GET',
          });

          const result = await response.json();
          console.log('getRecipient result:', result);
      } catch (error) {
          console.error("Network Error:", error);
      }
    },

    paypalMethod() {
      this.isPaypal = !this.isPaypal;
    },

    async getProfile() {
      const userDetails = {
          apiKey: 'ef7ad1d0-c278-480f-ba97-ea82da9c0514'
          };
    try {
        const response = await fetch('https://swingi.pro/api.php?action=wiseProfile', {
          method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(userDetails) // Envoyer les données à PHP
        });

        const result = await response.json();
        console.log('result profile:', result);
    } catch (error) {
        console.error("Network Error:", error);
    }
},




    async manageBank() {
      if (typeof window.createLemonSqueezy === 'function') {
      window.createLemonSqueezy();
      window.LemonSqueezy.Setup({
        eventHandler: async (data) => {
          if (data.event == "Checkout.Success") {
            console.log("Checkout successful!", data.data.order.data.attributes.identifier);
            const userDetails = {
            token: localStorage.getItem('authToken'),
            transactionID: data.data.order.data.attributes.identifier,
            count: this.count,
          };

          console.log('Save: ', userDetails);

          const response = await fetch('https://swingi.pro/api.php?action=manageBank', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(userDetails) // Envoyer les données à PHP
              });

              if (!response.ok){
                console.error('Error In Response', response);
              } else {
                console.log('Transaction Saved', response);
              }
                  }
                }
              })
            } else {
              console.error("Lemon.js script not loaded");
            }
    },


    async handlePurchase() {


      await this.updateQuote();
      
      try {
        this.transactionID = uuidv4();
        console.log('transaction ID : ', this.transactionID)
        const transferData = {
            quoteId: this.quoteId, // the quote ID you received
            sourceAmount: this.sourceAmount, // e.g., 100
            sourceCurrency: this.sourceCurrency, // e.g., 'USD'
            targetCurrency: this.targetCurrency, // e.g., 'EUR'
            customerTransactionId: this.transactionID,
        };

        const response = await fetch('https://swingi.pro/api.php?action=wiseTrans', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transferData)
        });
        const result = await response.json();
        if (result.errors) {
          console.error('error: ', result.errors[0].message);
          this.isWising = false;
        } else {
          const data = JSON.parse(result.data);
          console.log("Formatted JSON data:", data);
          try {
            const transferData = {
                customerTransactionId: this.transactionID,
            };

            const response = await fetch('https://swingi.pro/api.php?action=wiseShowBank', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(transferData)
            });
            console.log('res bank details : ', response);
            if (response.ok) {
                const result = await response.json(); // Extract the JSON content
                console.log('Parsed JSON Result:', result);

                // Check if the result contains the necessary data
                if (result.success) {
                    console.log("Bank details:", result.data);
                } else {
                    console.error('Error:', result.message);
                }
            } else {
                console.error('Failed to fetch data from the server. Status:', response.status);
            }
            
          } catch (error) {
            console.error("Network Error:", error);
            this.isWising = false;
          }
        }
        
      } catch (error) {
        console.error("Network Error:", error);
        this.isWising = false;
      }
    },

    async wisePurchase() {
      
      this.isWising = true;

      this.getRecipient();
      this.getProfile();
      
      try {
        const response = await fetch('https://swingi.pro/api.php?action=wisePay', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sourceCurrency: 'GBP', // Replace with user input or your choice
            targetCurrency: 'GBP',
            amount: this.count // Replace with user input or dynamic amount
          })
        });

        const data = await response.json();
        if (data.success) {
          this.wiseMethod = true;
          this.quoteId = data.data.id;  // Store the returned quote ID for use in handlePurchase
          this.sourceAmount = data.data.sourceAmount;
          this.sourceCurrency = data.data.sourceCurrency;
          this.targetCurrency = data.data.targetCurrency;
          console.log('data: ', data.data);
          console.log('Quote Created: ', this.quoteId, ' sourceAmount: ', this.sourceAmount, ' sourceCurrency: ', this.sourceCurrency, ' targetCurrency: ', this.targetCurrency);
        } else {
          console.error('Quote Error:', data.error || data.message);
        }
      } catch (error) {
        console.error('Request Failed:', error);
      }

      await this.handlePurchase();

      this.isWising = false;
    },

    async updateQuote() {
      try {
        const transactionId = uuidv4();
        const transferData = {
            quoteId: this.quoteId, // the quote ID you received
            sourceAmount: this.sourceAmount, // e.g., 100
            sourceCurrency: this.sourceCurrency, // e.g., 'USD'
            targetCurrency: this.targetCurrency, // e.g., 'EUR'
            customerTransactionId: transactionId,
        };
        const response = await fetch('https://swingi.pro/api.php?action=updateQuote', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(transferData)
        });

        const data = await response.json();
        if (data.success) {
          this.wiseMethod = true;
          //this.quoteId = data.data.id;  // Store the returned quote ID for use in handlePurchase
          //this.sourceAmount = data.data.sourceAmount;
          //this.sourceCurrency = data.data.sourceCurrency;
          //this.targetCurrency = data.data.targetCurrency;
          console.log('data: ', data.data);
          //console.log('Quote updated: ', this.quoteId, ' sourceAmount: ', this.sourceAmount, ' sourceCurrency: ', this.sourceCurrency, ' targetCurrency: ', this.targetCurrency);
        } else {
          console.error('Quote Error:', data.error || data.message);
        }
      } catch (error) {
        console.error('Request Failed:', error);
      }

    },


    async checkout(isCustom, checkOutLink, id, count) {
      this.isLoading = true;
      if(localStorage.getItem('authToken')) {
      if(isCustom) {
        
        // Créer un objet de données pour le checkout (similaire aux données que tu envoies à PHP)
      const orderData = {
        token: localStorage.getItem('authToken'),
        productId: id,   // Exemples de données
        count: count,
      };

      // Utiliser `fetch` pour appeler le script PHP
      await fetch('https://swingi.pro/LSPAPI.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderData) // Envoyer les données à PHP
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Erreur réseau lors de la création du checkout');
          }
          return response.json(); // Parse la réponse comme JSON
      })
      .then(data => {
        console.log('Checkout created:', data);
        this.checkoutURL = data.data.attributes.url;

        this.manageBank();

        // Vérification que LemonSqueezy est bien disponible
        if (window.LemonSqueezy) {
          window.LemonSqueezy.Url.Open(this.checkoutURL); // Ouvre le checkout
        } else {
          console.error('LemonSqueezy object not available');
        }
      })
      .catch(error => {
          console.error('Erreur lors de la création du checkout:', error);
      });

      
      
      } else {
        this.checkoutURL = checkOutLink;

        this.manageBank();

        // Vérification que LemonSqueezy est bien disponible
        if (window.LemonSqueezy) {
          window.LemonSqueezy.Url.Open(this.checkoutURL); // Ouvre le checkout
        } else {
          console.error('LemonSqueezy object not available');
        }
      }
      this.isLoading = false;
    } else {
        this.$router.push({ name: 'UserD', params: { id: 0 } });
      }
      
      
    },
    
    }
  };
  </script>
  
  <style>

  input[type="number"] {
    
    margin: 5px;
    width: calc(100% - 10px);
    height: 40px;
    min-height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f980;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    resize: vertical;
    overflow: hidden;
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

  }
  
  input[type="number"]:focus {
    border-color: #3498db;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  
  input[type="number"]::placeholder {
    color: #aaaaaa;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 40px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }
  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 20px; /* Ajuster la hauteur selon vos besoins */
    background-color: #ffe60080; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn2:hover {
    background-color: #ffe600; /* Couleur de fond lors du survol */
  }

  
  </style>
  
  