<template>
  
    <div class="body1">
      

      <div class="profile-container">
        <div v-if="notificationVisible" class="notification">{{ notificationMessage }}</div>
        <div v-if="errorVisible" class="notification-error">{{ errorMessage }}</div>
        <div class="profile-box-image">
          <img 
            :src="profileImage" 
            alt="Profile Image" 
            @click="triggerFileInput"
            class="profile-image"
          />
          <input 
            type="file" 
            ref="fileInput" 
            @change="uploadImage" 
            style="display: none;"
          />
        </div>
      </div>

      <div class="userBtn1" >
        <div class="moneyBtn1">
          <div class="moneyBox1">
            {{ sPoint }}
            <div>
              <svg fill="#ff5555" width="30px" height="30px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                
                <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                
                </svg>
            </div>
          </div>
        </div>
      </div>
      
     
          
          <div class="user-title" >
            
            <div v-if="updatingName" :style="{width: '100%'}">
              
              <input v-model="userName" :style="{width: '100%', height: '40px'}"/>
              <div v-if="!isSavingName" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updateName">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
              
            </div>

            <div v-else class="user-name">
              {{ userName }}
                <div class="svg-box" @click="updateName">
                  <img src="https://swingi.pro/icons/svg/Design/pencil-fill.svg" class="svg" alt="Edit Icon" />
                </div>
            </div>
            
          </div>

          <div class="email-title">

            <div v-if="updatingEmail" :style="{width: '100%'}">
              <input v-model="email" :style="{width: '100%', height: '40px'}"/>
              <div v-if="!isSavingEmail" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updateEmail">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
            </div>

              <div v-else class="email">
                {{ email }}

                <div class="svg-box" @click="updateEmail">
                  <img src="https://swingi.pro/icons/svg/Design/pencil-fill.svg" class="svg" alt="Edit Icon" />
                </div>
                
              </div>
              
              

            
          </div>

          <div class="phone-title">

            <div v-if="updatingPhone" :style="{width: '100%'}">
              <input v-model="phoneNumber" :style="{width: '100%', height: '40px'}"/>
              <div v-if="!isSavingPhone" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updatePhone">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
            </div>

            <div v-else class="phone">
              {{ phoneNumber }}
              <div class="svg-box" @click="updatePhone">
                <img src="https://swingi.pro/icons/svg/Design/pencil-fill.svg" class="svg" alt="Edit Icon" />
              </div>
              
          </div>

          
            
          </div>


          



    </div>
</template>



<script>

import Loading from '../components/items/loading.vue';

export default {
    name: "UserCardInfo",

    components: {
      Loading
    },

    data() {
    return {
      profileImage: '/g.png', // URL par défaut de l'image
      sPoint: 0,
      userName: '',
      email: '',
      phoneNumber: '',
      notificationMessage: '',
      notificationVisible: false,
      errorVisible: false,
      token: localStorage.getItem('authToken'), // Assurez-vous de définir le jeton approprié
      notification: '', // Notification message
      errorMessage: '', // Error message
      notificationType: '', // 'success' or 'error'
      updatingName: false,
      updatingEmail: false,
      updatingPhone: false,
      isSavingName: false,
      isSavingEmail: false,
      isSavingPhone: false,
      nm: '',
      em: '',
      ph: '',
    };
  },
  created() {
    this.searchUserImage();
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click(); // Ouvre le sélecteur de fichiers
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },

    async searchUserImage(){
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].profileImage !== '' && info.data[foundTokenIndex].profileImage !== null) {
            const timestamp = new Date().getTime();
            this.profileImage = `${info.data[foundTokenIndex].profileImage}?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          } else {
            const timestamp = new Date().getTime();
            this.profileImage = `/g.png?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          }
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.userName = info.data[foundTokenIndex].userName;
          } else {
            this.userName = info.data[foundTokenIndex].token;
          }

          if (info.data[foundTokenIndex].email !== '' && info.data[foundTokenIndex].email !== null) {
            this.email = info.data[foundTokenIndex].email;
          } else {
            this.email = 'Add Your Email';
          }

          if (info.data[foundTokenIndex].phoneNumber !== '0' && info.data[foundTokenIndex].phoneNumber !== null) {
            this.phoneNumber = info.data[foundTokenIndex].phoneNumber;
          } else {
            this.phoneNumber = 'Add Your Phone Number';
          }
          
        }

        this.sPoint = localStorage.getItem('sPoint');
        this.revealEffect();
    },
    async uploadImage() {
      const fileInput = this.$refs.fileInput;
      const formData = new FormData();
      formData.append('fileToUpload', fileInput.files[0]);
      formData.append('token', this.token);

      try {
        const response = await fetch('https://swingi.pro/api.php?action=uploadImage', {
          method: 'POST',
          body: formData
        });

        // Lire la réponse en tant que texte pour le débogage
        const textResponse = await response.text();
        console.log('Response Text:', textResponse);

        // Convertir la réponse en JSON
        const data = JSON.parse(textResponse);
        if (data.success) {
          
          const timestamp = new Date().getTime();
          this.profileImage = `${data.imageUrl}?t=${timestamp}`;// Mettez à jour l'URL de l'image
          this.showNotification('Image uploaded successfully');
          await this.updateUser(this.token, 'profileImage', `https://swingi.pro${this.profileImage}`);
          console.log('Donneés mises à jour');
        } else {
          console.error('Error uploading image:', data.error);
          this.showError(`Error uploading image: ${textResponse.error}`);
        }
      } catch (error) {
        console.error('Error:', error);
        this.showError(`Error uploading image: ${error}`);
      }
    },
    showError(message) {
      this.errorMessage = message;
      this.errorVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.errorVisible = false;
      }, 5000);
    },
    showNotification(message) {
      this.notificationMessage = message;
      this.notificationVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.notificationVisible = false;
      }, 5000);
    },



    async updateUser(token, field, value) {
      const updateData = {
        token: token,
        field: field,
        value: value,// Assignez l'URL de l'image téléchargée
      };

      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateUserDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          console.log('User updated successfully');
        } else {
          console.error('Error updating user:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async updateName() {
      
      
      if (this.updatingName) {
        this.isSavingName = true;
        await this.updateUser(this.token, 'userName', this.userName);
        localStorage.setItem('userName', this.userName);
        this.isSavingName = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingName = !this.updatingName;

      
    },
    async updateEmail() {
      
      
      if (this.updatingEmail) {
        this.isSavingEmail = true;
        await this.updateUser(this.token, 'email', this.email);
        localStorage.setItem('userEmail', this.email);
        this.isSavingEmail = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingEmail= !this.updatingEmail;

      
    },

    async updatePhone() {

      if (this.updatingPhone) {
        this.isSavingPhone = true;
        await this.updateUser(this.token, 'phoneNumber', this.phoneNumber);
        this.isSavingPhone = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingPhone= !this.updatingPhone;
      

    },

    isSyncing() {
      if (this.nm === '') {
        this.nm = this.userName;
      } else {
        this.userName = this.nm;
      }

      if (this.em === '') {
        this.em = this.email;
        
      } else {
        this.email = this.em;
      }
      if (this.ph === '') {
        this.ph = this.phoneNumber;
        
      } else {
        this.phoneNumber = this.ph;
      }
    },

    cancel() {
      this.isSyncing();

      this.updatingName= false;
      this.updatingEmail= false;
      this.updatingPhone= false;
    }
  }
}


</script>

<style>




.body1 {
    
    width: 30%; /* Prendre toute la largeur du parent */
    min-width: 300px;
    border-radius: 6px;
    background: rgb(255, 243, 243);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    padding-block: 20px;
    

  }

  .profile-container {
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  
  .notification {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(115, 220, 115, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 5s forwards;
  }

  .notification-error {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(224, 100, 100, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 5s forwards;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .profile-box-image{
    position: relative;

  }

  .svg-box{
    position: relative;

  }



  .profile-image {
    cursor: pointer;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #ccc;

    
  }

  .svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-inline: 5px;

  }

  .user-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 5px;
    font-family: 'Playwrite CU', sans-serif;
    font-size: 18px; /* Ajuster la taille de la police selon vos besoins */

  }

  .editing {
    display: flex;
    justify-content: space-between; /* Center horizontally */
    align-items: center; /* Center vertically */

  }

  .email-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;

  }

  .phone-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;

  }

  .user-name {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 90%;
    
    color: black; /* Ajuster la couleur du texte selon vos besoins */
    
    
  }

  .email {
    
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    max-width: 90%;
    white-space: nowrap;        /* Empêche le retour à la ligne */
    overflow: hidden;           /* Cache le texte qui dépasse */
    text-overflow: ellipsis;    /* Ajoute des points de suspension "..." */
  }
  .phone {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    max-width: 90%;
    white-space: nowrap;        /* Empêche le retour à la ligne */
    overflow: hidden;           /* Cache le texte qui dépasse */
    text-overflow: ellipsis;    /* Ajoute des points de suspension "..." */
  }

  .title {

    padding: 10px;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .text {
    width: 60%;
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .child {
    width: 100%;
    height: 40px;

    padding: 10px;
    display: flex;
    justify-self: center;
    align-items: center;

  }

  .input {
    width: 40%;
    height: 35px;

  }
  
  .input input:hover, 
  .input input:focus {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border-radius: 2px;
    padding-left: 2px;
    font-size: 12px; /* Changez la taille de la police ici */
  }

  .input input:not(:focus) {
    padding-left: 2px;
    font-size: 12px; /* Taille de police lorsque l'élément perd le focus */
    border-color: black;
  }
  
  .input input::placeholder {
    padding-left: 2px;
    color: gray;
    font-size: 12px; /* Changez la taille de la police du placeholder ici */
  }



  .boxBtn {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


  .input input {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 16px;

  }

  .userBtn1 {

    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
  
  }

  .moneyBtn1{
    margin-block: 5px;
    background-color: rgba(118, 187, 180, 0.2);
    border: 2px solid rgba(255, 255, 255, .9);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 18px;
    padding-right: 5px;
    padding-left: 5px;
    
    height: 60px;
    max-width: 250px;
    min-width: 100px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  
  
  .moneyBtn1:hover {
    background-color: #ff5555;
    color: #fff;
    transform: scale(1.05);
  }
  
  .moneyBox1{
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  
  .moneyBtn1 svg {
    fill: currentColor;
    stroke: currentColor;
  }

</style>