
<template>
    <div class="signbody">
      <div class="wrapper">
        <form @submit.prevent="btnClick">
          <h1>Sign In</h1>
        <div class="input-box">
          <input type="name" v-model="userName" placeholder="Name" class="reveal-input" required>
          <img class="imageSvg" src="/icons/svg/User & Faces/user-2-fill.svg" alt="Artboard">         
        </div>
          <div class="input-box">
            <input v-model="email" type="email" placeholder="Email" class="reveal-input" required>
            <img class="imageSvg" src="/icons/svg/Business/mail-fill.svg" alt="Artboard">  
          </div>
          <div class="input-box">
            <input :type="passwordFieldType" v-model="password" placeholder="Password" class="reveal-input" required/>
            <img v-if="passwordFieldType === 'password'" @click="togglePasswordVisibility" class="imageSvg" src="/icons/svg/System/lock-fill.svg" alt="Artboard">  
            <img v-if="passwordFieldType === 'text'" @click="togglePasswordVisibility" class="imageSvg" src="/icons/svg/System/lock-unlock-fill.svg" alt="Artboard">  
            
          </div>

          <div class="input-box">
            <input :type="passwordFieldType2" v-model="confirmPassword" placeholder="Confirme Password" class="reveal-input" required>
            <img v-if="passwordFieldType2 === 'password'" @click="togglePasswordVisibility2" class="imageSvg" src="/icons/svg/System/lock-fill.svg" alt="Artboard">  
            <img v-if="passwordFieldType2 === 'text'" @click="togglePasswordVisibility2" class="imageSvg" src="/icons/svg/System/lock-unlock-fill.svg" alt="Artboard">  

          </div>

          <div v-if="!promoCode" class="promoCodeText" @click="promo">
            I Have Promotional Code
          </div>
          <div v-else class="promoCodeText">
            <input v-model="promoCodeText" type="promoCode" placeholder="XXX-XXX-XXX-XXX" @blur="handlePromoCodeChange">
            <font-awesome-icon v-if="!isRefered" :icon="['fas', 'gift']" class="imageIcon"/>
            <font-awesome-icon v-else-if="isRefered && isReferedCorrect" :icon="['fas', 'check']" class="imageIcon imageIconTrue"/>
            <font-awesome-icon v-else :icon="['fas', 'times']" class="imageIcon imageIconFalse"/>
          </div>


          <div class="info">
            <p>by registering, you accept our terms of use. <button class="link-button" @click="register">View Terms</button></p>
        </div>
  
          <div v-if="!isSignIn">
            <button type="submit" @click="signIn" class="g-recaptcha btn">{{signText}}</button>
          </div>
          <div v-else class="loader">
            <Loading type="submit" :width="'50px'"/>
          </div>

          

    

        </form>

        <div v-if="!isGoogleSignIn" @click="googleSignIn" class="g-recaptcha google-signin-button">
          {{ googleSignText }}
        </div>

        <div v-else class="loader">
          <Loading type="submit" :width="'50px'"/>
        </div>
        
      </div>
      
      <div class="login" @click="register">
        <div :style="{fontSize: '10px'}">
          to
        </div>
        
        <div :style="{fontSize: '14px', fontWeight: 'bold'}"></div>
          LogIn
        </div>
        
      </div>
      
    
    

  </template>
  
  <script>
import { auth, provider, appCheck } from '../firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import Loading from '../components/items/loading.vue';
  
  export default {
    name: "SignCard",
    

    
    components: {
      Loading,
  },
  
    data() {
      return {
        userName: '',
        email: '',
      password: '',
      confirmPassword: '',
        passwordFieldType: 'password',
        passwordFieldType2: 'password',
        remember: false,
        isSignIn : false,
        isGoogleSignIn : false,
        signText: 'Sign With Swingi',
        googleSignText: 'Google Sign In',
        promoCode: false,
        promoCodeText: '',
        isRefered: false,
        isReferedCorrect: false,
        recaptchaOptions: {
        siteKey: '6LfCGWcqAAAAAHRATe9JJKnkpRkMveL-5KPWARsV', // Replace with your site key
        size: 'invisible', // Or 'compact'
      },
      recaptchaToken: null,
        
        
      };
    },

    mounted() {
        this.revealEffect(true);  // Lance l'effet dès que le composant est monté
    },

    methods: {
      togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        if (this.passwordFieldType !== 'password') {
          this.revealEffect(true);
        }
      },
      togglePasswordVisibility2() {
        this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password';
        if (this.passwordFieldType2 !== 'password') {
          this.revealEffect(false);
        }
      },

      async signIn(reToken) {
        this.isSignIn = true;
        try {
          if (this.password != this.confirmPassword) {
            this.signText = 'PasseWord Must be the same !';
            this.isSignIn = false;
          } else {
            const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
          const user = userCredential.user;
          console.log('user email: ', user.email);
          await this.sendConfirmationEmail(reToken, user.email, false);
          //await sendEmailVerification(user);
          localStorage.setItem('userName', this.userName);
          

          this.saveUserDetails(user.uid, this.userName, null, user.email, '0', '0', this.promoCodeText);
          //this.saveUserDetails(user.uid, this.userName, null, user.email, '0', '0');
          //console.log('stored:', 'id: ',user.uid, 'email: ',user.email);

          this.isSignIn = false;
          //window.location.href = this.$router.resolve({ name: 'EmailVerification' }).href;
          }
          


      } catch (error) {
        this.isSignIn = false;
        // Handle different types of errors
        switch (error.code) {
          case 'auth/email-already-in-use':
          this.signText = 'Email address already in use !';
            break;
          case 'auth/invalid-email':
          this.signText = 'The email address is not valid !';
            break;
          case 'auth/operation-not-allowed':
          this.signText = 'Not allowed to register !';
            break;
          case 'auth/weak-password':
          this.signText = 'The password is too weak !';
            break;
          default:
            if (!this.email && !this.userName) {
              this.signText = 'Pleas enter your email and name !';
            } else if (!this.email) {
              this.signText = 'Pleas enter your email !';
            } else if (!this.userName) {
              this.signText = 'Pleas enter your name !';
            } else {
              this.signText = 'Error registring user, Retry !';
            }
          
        }

        
      }
    },

  loadUserData() {
    return {
      token: localStorage.getItem('authToken'),
      email: localStorage.getItem('userEmail'),
      password: localStorage.getItem('userPassword'),
    };
  },

    
    login() {
      window.location.href = this.$router.resolve({ name: 'Home' }).href;
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

    async revealEffect(creation) {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
            let randomText = '';
            
            const currentText = this.password;
            const currentText2 = this.confirmPassword;

            if (creation === true) {
              if (currentText) {
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText + randomL;
                await this.wait(30);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText;
                this.password = randomText + randomL2;
                await this.wait(30);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.password = randomText;

            }
            } else {
              for (let i = 0; i < 15; i++) {
                  // Sinon, on génère une lettre aléatoire
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText + randomL;
                await this.wait(30);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText;
                this.password = randomText + randomL2;
                await this.wait(30);
                randomText += characters.charAt(Math.floor(Math.random() * characters.length));
                  // Met à jour le texte affiché
                this.password = randomText;
              }
                  
                
            }
            } else {
              for (let i = 0; i < currentText2.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.confirmPassword = randomText + randomL;
                await this.wait(30);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.confirmPassword = randomText;
                this.confirmPassword = randomText + randomL2;
                await this.wait(30);
                randomText += currentText2[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.confirmPassword = randomText;

            }
            }

            

            
        },



        async generateRecaptchaToken(token) {
          if (appCheck) {
            console.log("App Check initialized: ", appCheck);
            try {
              const tokenResponse = token; // Appelle getToken sans passer de paramètre
              console.log('reCaptcha Token:', tokenResponse);
              return token; // Retourne le token
            } catch (error) {
              console.error("Erreur lors de la récupération du token reCAPTCHA :", error);
              throw error; // Lance l'erreur pour la gérer ailleurs
            }
          } else {
            console.error("App Check not initialized.");
          }
        // Récupérer le token reCAPTCHA
        
      },


        
        async sendConfirmationEmail(reCaptcha, email, isGoogle) {

          const token = await this.generateRecaptchaToken(reCaptcha); // Appel correct de la méthode

          const sendInfo = {
            email: email,
            recaptchaToken: token,

          }

          if (token) {
            try {
              const response = await fetch('https://swingi.pro/api.php?action=sendEmail', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(sendInfo)
              });

              const result = await response.json();
              console.log(result);
              if (isGoogle) {
                this.googleSignText = result.data;
                } else {
                  this.signText = result.data;
                }
            } catch (error) {
              if (!email) {
                if (isGoogle) {
                  this.googleSignText = 'Pleas Enter valid Email, then try again.';
                } else {
                  this.signText = 'Pleas Enter valid Email, then try again.';
                }
                
              } else {
                if (isGoogle) {
                  this.googleSignText = 'Error fetching code : 01';
                } else {
                  this.signText = 'Error fetching code : 01';
                }
                
              }
              
            }
            
          } else {
            this.googleSignText = 'No reCaptcha Token found';
          }

          


        },

    
    
        async googleSignIn(reToken) {
          this.isGoogleSignIn = true;

          try {
            const userCredential = await signInWithPopup(auth, provider);
            const user = userCredential.user;

            await this.sendConfirmationEmail(reToken, user.email, true);
            

            // Stocker les informations de l'utilisateur localement

            // Appeler la fonction pour sauvegarder les détails de l'utilisateur
            this.saveUserDetails(user.uid, user.displayName, user.photoURL, user.email, '0', '0');

            //window.location.href = this.$router.resolve({ name: 'Home' }).href;
            this.isGoogleSignIn = false;

          } catch (error) {
            this.isGoogleSignIn = false;
            switch (error.code) {
              case 'auth/email-already-in-use':
              this.googleSignText = 'Email address already in use.';
                break;
              case 'auth/invalid-email':
              this.googleSignText = 'The email address is not valid.';
                break;
              case 'auth/operation-not-allowed':
              this.googleSignText = 'Firebase enabling ERROR';
                break;
              case 'auth/weak-password':
              this.googleSignText = 'The password is too weak.';
                break;
              default:
              this.googleSignText = 'Error registering user: RETRY';
            }
          }
        
        

        },

        async handlePromoCodeChange() {
            this.isReferedCorrect = false;
            this.isRefered = false;
            console.log(this.promoCodeText);
            const promoCode = {
            referCode: this.promoCodeText,

          }
            try {
            const response = await fetch('https://swingi.pro/api.php?action=verifyRefer', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(promoCode)
              
            });
            const data = await response.json();
            if (data.success) {
                this.isReferedCorrect = true;
                this.isRefered = true;
            } else {
              this.isReferedCorrect = false;
              this.isRefered = true;
            }
          } catch (error) {
            console.error('Error verifying user:', error);
            this.isReferedCorrect = false;
            this.isRefered = false;
            //this.$router.push({ name: 'Home' });
          }
        },

        async saveUserDetails(token, userName, profileImage, email, phoneNumber, insta, promo) {
          const userDetails = {
            token: token,
            userName: userName,
            profileImage: profileImage,
            email: email,
            phoneNumber: phoneNumber,
            insta: insta,
            promo: promo,
            dayTime: new Date().toISOString(),
            referCode: this.promoCodeText,
          };

          console.log('Save: ', userDetails);

          const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
          
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const info = await response.json();
                
                const foundTokenIndex = info.data.findIndex(item => item.token === token);
                console.log('res1 : ', foundTokenIndex, info.data);
                

                if (foundTokenIndex === -1) {
                  try {
                    const response2 = await fetch('http://swingi.pro/api.php?action=saveUserDetails', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userDetails)
                    });
                    const info2 = await response2.json();
                    console.log('res2 : ', info2);
                  } catch (e) {
                      console.error('error : ', e);
                  }
                  
                }
        },

        promo() {
          this.promoCode = true;
        },

          
          
        register() {
          this.$emit('register-click');
        },

    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles personnalisés si nécessaire */

  
  .signbody {
    margin-top: 200px;
    width: 80%;
    min-width: 300px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 10px;
    flex-direction: column;
  }


  .google-signin-button {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    outline: none;
    border-radius: 6px;
    background: rgba(255, 255, 255, .5);
    border: 2px solid rgba(255, 255, 255, .8);
    backdrop-filter: blur(2px);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  

  .google-signin-button:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    
    background: linear-gradient(45deg, #4285F480, #F4B40080, #0F9D5880);
    background-size: 300% 300%;
    animation: gradientAnimation 3s ease infinite;
  }
  
  @keyframes gradientAnimation {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
  }

  .login {
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 10px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
  }
  


  .important {
    margin-top: 25px;
    width: 100%;
    
    font-size: 12px;
    text-align: center;
    color: #ff5555;
    
  }
  .info {
    margin-bottom: 10px;
    width: 100%;
    
    font-size: 12px;
    text-align: center;
    color: black;
    
  }
  
  .wrapper h1 {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    margin-block: 20px;
  }
  
  .wrapper .input-box {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 20px 0;
  }

  
  
  .input-box input {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .8);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  }

  .input-box input:hover, input:focus {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
  }
  
  .input-box input::placeholder {
    
    color: gray;
  }
  
  .input-box svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }

  .input-box img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
  
  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    
    margin: -15px 0 15px;
  }
  
  .remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
    height: 14px;
    width: 14px;
  }
  
  .remember-forgot a {
    color: gray;
    text-decoration: none;
  }
  
  .remember-forgot a:hover {
    text-decoration: underline;
  }
  
  .wrapper .btn {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
  }

  .btn:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    color: white;
      background: linear-gradient(45deg, #fd1d1d80, #fcb04580, #833ab480, #fd1d1d80);
      background-size: 300% 300%;
      animation: gradientAnimation 5s ease infinite;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .wrapper .register-link {
    font-size: 12px;
    text-align: center;
    margin: 20px 0 15px;
    
  }
  
  .register-link p a {
    color: gray;
    text-decoration: none;
  }
  
  .register-link p a:hover {
    text-decoration: underline;
  }

  .link-button {
    background: none;
    border: none;
    color: gray;
    cursor: pointer;
    text-decoration: underline;
    font-size: inherit;
  }
  
  .link-button:hover {
    color: #ff5555;
  }


  .promoCodeText {
    position: relative;
    margin-block: 10px;
    background: none;
    color: blue;
    cursor: pointer;
    font-size: inherit;
    display: flex;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-color: blue;
  }

  .promoCodeText img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }

  

  .promoCodeTextt {
    margin-block: 10px;
    background-color: black;
    color: #ff5555;
    cursor: pointer;
    font-size: inherit;
    display: flex;
    justify-content: center;
    font-size: 18px;
    border-radius: 0%;
  }
  .promoCodeText:hover {
    background-color: black;
    color: white;
  }

  input[type="promoCode"] {
    
    margin: 5px;
    width: calc(100% - 10px);
    height: 20px;
    border-radius: 0px;
    background-color: transparent;
    transition: all 0.3s ease;
    outline: none;
    resize: vertical;
    overflow: hidden;
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

  }
  
  input[type="promoCode"]:focus {
    height: 20px;
    border-radius: 0px;
    background-color: transparent;
    font-family: 'Josefin Sans', sans-serif;
    
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  input[type="promoCode"]:hover {
    height: 20px;
    border-radius: 0px;
    background-color: transparent;
    font-family: 'Josefin Sans', sans-serif;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  input[type="promoCode"]::placeholder {
    height: 20px;
    color: #ffffff;
  }

  .reveal-input {
    font-size: 24px;
    color: black;
    background-color: transparent;
    padding: 10px;
    outline: none;
    width: 300px;
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease-in-out;
}
.imageSvg {
  width: 24px;
  height: 24px;
  background-size: contain;
}

.imageIcon {
  width: 20px;
  height: 20px;
  background-size: contain;
  position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
}

.imageIconTrue {
  color: green;
}

.imageIconFalse {
  color: red;
}


  </style>
  