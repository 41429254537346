<template>
    <header>
      <div v-if="isMenuVisible"   
      :style="{
        width: '100vw', 
        height: '100vh', 
        backgroundColor: 'transparent', 
        position: 'fixed', 
        top: '0', 
        left: '0', 
        zIndex: '0',
        overflow: 'hidden'
      }"  @click="isMenuVisible = false">

      </div>
        <nav v-if="token" class="navbar-contents">
          <RouterLink to="/" class="swingi-logo">
            <img src="/swingi.png" alt="Swingi logo" />
          </RouterLink>

          <div class="contents">

            <RouterLink to="/Store" class="hamburg">
              <img class="storeImg" src="/icons/svg/Buildings/store-2-fill.svg" alt="Artboard">
            </RouterLink>
            
            <div class="userBtn" >
              <div class="moneyBtn" @click="handleClick2">
                <div v-if="!isGettingSP" class="moneyBox" >
                  {{ sPoint }}
                </div>
                <div v-else class="moneyBox" >
                  <Loading type="submit" :width="'20px'"/>
                </div>
                <div>
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
                </div>
                
              </div>
    
              
              <div class="usrBox">
                <button @click="handleClick" :class="[isHovering ? 'hover-a-class' : 'normal-h-class']"
                @mouseover="isHovering = true"
                @mouseleave="isHovering = false">
                  <div class="flex items-center">
                    
                      <img :src="proImage" alt="User Icon" class="profile" />
                    
                    
                    <h4 :class="{
                      'text-white font-bold': isHovering,
                      'text-xs': true,
                      'max-w-150px': true,
                      'overflow-hidden': true,
                      'text-ellipsis': true,
                      'whitespace-nowrap': true,
                    }" style="padding: 5px;">
      
                  {{ user }}
                </h4>
              </div>
          
        </button>
              </div>
              <div @click="toggleSidebar" class="hamburg" >
                <svg fill="#000000" width="40px" height="40px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><title>Artboard</title><path d="M768 306.2V383H256v-76.8h512zM256 536.6h512v-76.8H256v76.8zm0 153.6h512v-76.8H256v76.8z" fill-rule="evenodd"/></svg>
              </div>

              <div v-if="isMenuVisible" class="sidebar">
                <div @click="handleClickOutside" class="exit">
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z"/></svg>
                </div>
                <ul>
                  <li>

                      <svg width="30px" height="30px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icons">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6666 8L17.75 10.5L15.6666 8Z" stroke="#ff5555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6666 13L17.75 10.5L15.6666 13Z" stroke="#ff5555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 10.5L10 10.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        <line x1="4" y1="3.5" x2="13" y2="3.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        <line x1="4" y1="17.5" x2="13" y2="17.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        <path d="M13 3.5V7.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        <path d="M13 13.5V17.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        <path d="M4 3.5L4 17.5" stroke="#ff5555" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      <button @click="disconnect">Disconnect</button>

                    </li>
                    
                  
                </ul>
              </div>

              
            </div>
          </div>
          
          
          
        </nav>
        <nav v-else class="navbar-contents">
          <RouterLink to="/" class="swingi-logo">
            <img src="/swingi.png" alt="Swingi logo" />
          </RouterLink>
          <div class="usrBox">
            <button @click="handleClick" :class="[isHovering ? 'hover-a-class' : 'normal-h-class']"
              @mouseover="isHovering = true"
              @mouseleave="isHovering = false">
                <div class="flex items-center">
                                  
                  <h4 :class="{
                    'text-white font-bold': isHovering,
                    'text-xs': true,
                    'max-w-150px': true,
                    'overflow-hidden': true,
                    'text-ellipsis': true,
                    'whitespace-nowrap': true,
                  }" style="padding: 5px;">

                Login | Signin
              </h4>
          </div>
      
    </button>
          </div>
        </nav>
      </header>
</template>

<script>
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Loading from '../components/items/loading.vue';


export default {
    name: "HeaderNavbar",

    components: {
      Loading,
  },
  
    
    props: {
    userId: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    swingiPoint: {
      type: String,
      required: false
    }
    
  },


  data() {
    return {
      isGettingSP: true,
      isHovering: false,
      hamIsHovering: false,
      token: '',
      user: 'Start',
      proImage: '',
      isMenuVisible: false,
      sPoint: '0',
      sCoin: '0',

    };
  },
  created() {
    
      //token: localStorage.getItem('authToken');
      //email: localStorage.getItem('userEmail');
      if(localStorage.getItem('authToken') != null){
        
        this.token = localStorage.getItem('authToken');
        if(localStorage.getItem('userName')) {
          this.user = localStorage.getItem('userName');
        } else {
          this.user = this.token;
        }
        if(localStorage.getItem('proImage')) {
          this.proImage = localStorage.getItem('proImage');
          console.log('image found : ', this.proImage);
        } else {
          this.proImage = '/g.png';
          console.log('image not found.');
        }

        setTimeout(() => {
      this.getUserBank();
    }, 5000);

      
      } else {
        this.proImage = '/g.png';
          console.log('image not found.');
      }
      
      
    
  },

  mounted() {
    this.searchUserImage();
  },

  

  methods: {
      handleClick() {
      // Redirection vers la page de login ou utilisateur
      this.$router.push({ name: 'UserD', params: { id: this.userId } });
    },

    handleClick2() {
      console.log('Button clicked');
      // Ajoutez votre logique ici
    },

    toggleSidebar() {
      this.isMenuVisible = !this.isMenuVisible; // Toggle visibility
    },
    handleClickOutside() {
      this.isMenuVisible = false; // Close sidebar on outside click
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },


    async getUserBank(){
      this.isGettingSP = true;
      try {
        const response = await fetch('https://swingi.pro/api.php?action=getBankDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        
        console.log('found Token Index : ', info.data[foundTokenIndex].sPoint);  

        if (foundTokenIndex !== -1) {

          
          this.sPoint = info.data[foundTokenIndex].sPoint;
          this.sCoin = info.data[foundTokenIndex].sCoin;
          localStorage.setItem('sPoint', this.sPoint);
          this.revealEffect();
          
          
        } else {
          console.log('No Bank Yet');
        }
        this.isGettingSP = false;
      }catch (error){
        this.isGettingSP = false;
        console.error(error);
      }
        
    },

    

    disconnect() {
      
      localStorage.removeItem('authToken');
      localStorage.removeItem('proImage');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('instagramUser');
          localStorage.removeItem('instaUser');
          localStorage.removeItem('instaLinkHD');
          localStorage.removeItem('country');
          localStorage.removeItem('qst');
          localStorage.removeItem('answer');
          localStorage.removeItem('statisticsID');
          localStorage.removeItem('dayTime');
      console.log('disconnected');
      
      window.location.href = this.$router.resolve({ name: 'Home' }).href;
    },

    autoSignIn() {
    const userData = this.loadUserData();
    if (userData.token && userData.email && userData.password) {
      signInWithEmailAndPassword(auth, userData.email, userData.password)
        .then((userCredential) => {
          console.log('User signed in automatically:', userCredential.user);
          this.$router.push({ name: 'Home' });
        })
        .catch((error) => {
          console.error('Error signing in automatically:', error.message);
        });
    } else {
      console.error('No user acount found, pleas login/register');
    }
  },

  async searchUserImage(){

    if (localStorage.getItem('authToken')) {

      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.user = info.data[foundTokenIndex].userName;
          } else {
            this.user = info.data[foundTokenIndex].token;
          }
          
        }
    } else {
      this.isGettingSP = false;
      this.sPoint = 0;
    }
      
    },
    },

}
</script>

<style scoped>



.navbar-contents {
  position: fixed;
  width: 80%;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, .2);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  border-radius: 10px;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 10px);
  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */
  z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

}


.hamburg {
  width: 40px;
  height: 40px;
  backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.hamburg:hover {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  cursor: pointer;
}

.svg-icons {
  
  margin: 5px;
  width: 20px;
}

.storeImg {
  
  margin: 5px;
  width: 20px;
}

.actions {
  margin-top: 10px;
  width: 120px;
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
  z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

}

.exit {
  margin: 2px;
  position: fixed;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  backdrop-filter: blur(2px);
  border-radius: 10px;
}

.exit:hover {
  margin: 2px;
  position: fixed;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 50px;
  width: 250px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  border-radius: 5px;
  color: white;
  padding: 15px 2px 2px 2px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  z-index: 50000;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  display: flex;
  align-items: center; /* Centre verticalement les éléments dans le li */
  justify-content: center; /* Centre horizontalement les éléments dans le li */
  height: 30px;
  margin: 10px 0;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
}

.sidebar li svg {
  vertical-align: middle; /* Aligne le SVG avec le texte */
}

.sidebar button {
  margin-left: 10px; /* Ajouter un peu d'espace entre le SVG et le texte si nécessaire */
}

.sidebar li:hover  {
  display: flex;
  justify-content: center;
  height: 30px;
  color: #ff5555;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  margin: 10px 0;
}

.sidebar a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.swingi-logo{
  width: 120px;

}

.contents{
  display: flex;
  align-items: center;

}

.profile {
  margin-right: 5px;
  height: 30px;
  width: 30px;
  object-fit: cover;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.moneyBtn{
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgba(118, 187, 180, 0.2);
  border: 2px solid rgba(255, 255, 255, .9);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 35%;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}



.moneyBtn:hover {
  background-color: #ff5555;
  color: #fff;
  transform: scale(1.05);
}

.moneyBox{
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.moneyBtn svg {
  fill: currentColor;
  stroke: currentColor;
}


.bankBtn{
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  max-width: 100px;
}
.normal-h-class {
    padding: 2px;
    max-width: 150px;
    height: 2.5rem; /* h-20 equivalent */
    background-color: transparent;
}

.usrBox{
  
  padding-right: 5px;
  max-width: 150px;
  min-width: 68px;
  font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
  font-weight: bold;
}

.userBtn {

  display: flex;
  justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
  align-items: center; /* Centre les éléments verticalement */

}

.hover-a-class {
  border-radius: 8px;
  max-width: 150px;
  padding: 2px;
    height: 2.5rem; /* h-10 equivalent */
    background: linear-gradient(45deg, #fd1d1d60, #fcb04560, #833ab460, #fd1d1d60);
    background-size: 300% 300%;
    animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
</style>