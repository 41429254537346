<template>
    <header>
      <nav v-if="token" class="mobile-navbar-contents">
        
            <div class="swingi-logo">
                <RouterLink to="/">
                    <img src="/swingi.png" alt="Swingi logo" />
                  </RouterLink>
            </div>
            
            <div class="userInfo">
                <button @click="handleClick">
                  <div class="flex items-center">
                    <img :src="proImage" alt="User Icon" class="mobile-profile" />
                    <h4 :class="{
                      'text-xs': true,
                      'max-w-150px': true,
                      'overflow-hidden': true,
                      'text-ellipsis': true,
                      'whitespace-nowrap': true,
                    }" style="padding: 5px;">
                    {{ user }}
                  </h4>
                  </div>
                </button>
              </div>
        
        
        
        
       
            
          
          <div class="actions">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-icons"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 64l224 0 0 9.8c0 39-23.7 74-59.9 88.4C167.6 186.5 128 245 128 310.2l0 73.8s0 0 0 0l-64 0L64 64zm288 0l224 0 0 320-67.7 0-3.7-4.5-75.2-90.2c-9.1-10.9-22.6-17.3-36.9-17.3l-71.1 0-41-63.1c-.3-.5-.6-1-1-1.4c44.7-29 72.5-79 72.5-133.6l0-9.8zm73 320l-45.8 0 42.7 64L592 448c26.5 0 48-21.5 48-48l0-352c0-26.5-21.5-48-48-48L48 0C21.5 0 0 21.5 0 48L0 400c0 26.5 21.5 48 48 48l260.2 0 33.2 49.8c9.8 14.7 29.7 18.7 44.4 8.9s18.7-29.7 8.9-44.4L310.5 336l74.6 0 40 48zm-159.5 0L192 384s0 0 0 0l0-73.8c0-10.2 1.6-20.1 4.7-29.5L265.5 384zM192 128a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/></svg>
            <button @click="disconnect">Disconnect</button>
          </div>

            <div class="moneyBtn" @click="handleClick2">
                <div v-if="!isGettingSP" class="moneyBox" >
                  {{ sPoint }}
                </div>
                <div v-else class="moneyBox" >
                  <Loading type="submit" :width="'20px'"/>
                </div>
                <div>
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
                </div>
                
              </div>
        

          <div class="actions2">
            <RouterLink to="/Store" class="hamburg">
              <img class="storeImg" src="/icons/svg/Buildings/store-2-fill.svg" alt="Artboard">
              
            </RouterLink>
            Store
          </div>

      </nav>
      <nav v-else class="mobile-navbar-contents">
          <div class="swingi-logo">
              <RouterLink to="/">
                  <img src="/swingi.png" alt="Swingi logo" />
                </RouterLink>
          </div>
          
          <div class="userInfo">
              <button @click="handleClick">
                <div class="flex items-center">
                  
                  <h4 :class="{
                    'text-xs': true,
                    'max-w-150px': true,
                    'overflow-hidden': true,
                    'text-ellipsis': true,
                    'whitespace-nowrap': true,
                  }" style="padding: 5px;">
                  Login | Signin
                </h4>
                </div>
              </button>
            </div>
      </nav>
    </header>
  </template>
  
  <script>
  import Loading from '../components/items/loading.vue';

  export default {
    name: "MobileNavbar",

    components: {
      Loading,
  },
  
    props: {
      userId: {
        type: String,
        required: true
      },
      username: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        isGettingSP: true,
        user: 'start',
        proImage: localStorage.getItem('proImage') ? localStorage.getItem('proImage') : '/g.png',
        sPoint: 0,
      };
    },

    created() {
    
    //token: localStorage.getItem('authToken');
    //email: localStorage.getItem('userEmail');
    if(localStorage.getItem('authToken') != null){
      
      this.token = localStorage.getItem('authToken');
      if(localStorage.getItem('userName')) {
        this.user = localStorage.getItem('userName');
      } else {
        this.user = this.token;
      }
      if(localStorage.getItem('proImage')) {
        this.proImage = localStorage.getItem('proImage');
      } else {
        this.proImage = '/g.png';
      }

      setTimeout(() => {
        this.getUserBank();
      }, 5000);

    
    }  
  
    },


    mounted() {
    this.searchUserImage();
  },
  
    methods: {
      handleClick() {
        this.$router.push({ name: 'UserD', params: { id: this.userId } });
      },
      disconnect() {
        localStorage.clear();
        window.location.href = this.$router.resolve({ name: 'Home' }).href;
      },

      wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },

      async getUserBank(){
        this.isGettingSP = true;
        try {
      const response = await fetch('https://swingi.pro/api.php?action=getBankDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        
        console.log('found Token Index : ', info.data[foundTokenIndex].sPoint);  

        if (foundTokenIndex !== -1) {

          
          this.sPoint = info.data[foundTokenIndex].sPoint;
          this.sCoin = info.data[foundTokenIndex].sCoin;
          localStorage.setItem('sPoint', this.sPoint);
          this.revealEffect();
          
          
        } else {
          console.log('No Bank Yet');
        }
        this.isGettingSP = false;
      }catch (error){
        this.isGettingSP = false;
        console.error(error);
      }
        
    },

    async searchUserImage(){
      if (localStorage.getItem('authToken')) {
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.user = info.data[foundTokenIndex].userName;
          } else {
            this.user = info.data[foundTokenIndex].token;
          }
          
        }
      } else {
      this.isGettingSP = false;
      this.sPoint = 0;
    }
  }
  }
  };
  </script>
  
  <style scoped>
  .mobile-navbar-contents {
    position: fixed;
    width: 100%;
    top: 10px;
  }

  .swingi-logo{
    position: fixed;
    top: 10px;
    left: 0px;
    padding: 2px;
    width: 120px;
    height: 45px;
    margin-inline: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }


  
  .mobile-profile {
    top: 10px;
    right: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    
  }
  
  .userInfo {
    position: fixed;
    top: 10px;
    right: 0px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    padding-inline: 5px;
    margin-inline: 10px;
    max-width: 150px;
    min-width: 68px;
    height: 45px;
    font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
    font-weight: bold;
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }

  .svg-icons {
    margin-right: 5px;
    margin-left: 5px;
    width: 20px;
  }

  .storeImg {
  
    margin: 5px;
    width: 20px;
  }
  
  .actions {
    position: fixed;
    top: 55px;
    left: 0px;
    margin-top: 10px;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 6000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;

  }

  .actions2 {
    position: fixed;
    top: 90px;
    left: 0px;
    margin-top: 10px;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 6000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;

  }

  .moneyBtn{
    position: fixed;
    top: 55px;
    right: 0px;
    margin-top: 10px;
    margin-inline: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .9);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 14px;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 35%;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }
  
  .moneyBtn:hover {
    background-color: #ff5555;
    color: #fff;
    transform: scale(1.05);
  }
  
  .moneyBox{
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .moneyBtn svg {
    fill: currentColor;
    stroke: currentColor;
    color: #ff5555;
  }

  .moneyBtn:hover  svg {
    fill: currentColor;
    stroke: currentColor;
    color: #fff;
  }
  </style>
  