<template>
    <div class="refer-box">
       <div v-if="!isRefer">
        
        <div class="title">
          <font-awesome-icon :icon="['fas', 'gift']" />
            Create your own Referal Code
        </div>
        
        <div :style="{display: 'flex', justifyContent: 'center'}">
            <input v-model="referCode" type="code" placeholder="ex: mycode" />
        </div>
        <div v-if="!isCreating" class="boxBtn">
            <button class="btn" @click="creatRefer">
            <div v-if="!searching" class="btnCtn">
                <div class="nameBtn">
                  Create
                </div>

              </div>
            </button>
            
        </div>
        <div v-else class="boxBtn" >
            <Loading type="submit" :width="'40px'"/>
          </div>
       </div>
       <div v-else>
        <div class="title">
          <font-awesome-icon :icon="['fas', 'gift']" />
            Referal Code :
        </div>
        <div :style="{display: 'flex', justifyContent: 'center', fontFamily: 'Josefin Sans, sans-serif', fontWeight: 'bold', fontSize: '2vh'}">
          {{ referCode }}
        </div>
        
        <div class="boxBtn">
            <button class="btn" @click="updateRefer">
                <div v-if="!searching" class="btnCtn">
                    <div class="nameBtn">
                      Change
                    </div>
    
                  </div>
                </button>
        </div>
       </div>
       

    </div>
</template>



<script>

import Loading from '../components/items/loading.vue';


export default {

    name: "ReferEarn",

    components: {
        Loading,
    },

    props: {

      token: {
        type: String,
        required: true
      },
    },

    data() {
    return {
        referCode: '',
        isCreating: false,
        isRefer : false,
    };
  },
  created() {
    if(localStorage.getItem('referCode')) {
        this.referCode = localStorage.getItem('referCode');
        this.isRefer = true;
    } else {
      this.getRefer();
    }
  },
  methods: {
    async getRefer() {
      this.isCreating = true;
        try {
        const response = await fetch('https://swingi.pro/api.php?action=getRefer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: this.token,
          }),
        });
        const data = await response.json();
        console.log('data : ', data);
        if (data.success) {
            localStorage.setItem('referCode', data.data);
            this.referCode = data.data;
            this.isCreating = false;
            this.isRefer = true;
        } else {
            this.isCreating = false;
        }
      } catch (error) {
        console.error('Error verifying user:', error);
        this.isCreating = false;
        //this.$router.push({ name: 'Home' });
      }
    },
    async creatRefer() {
        this.isCreating = true;
        try {
        const response = await fetch('https://swingi.pro/api.php?action=referCode', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            referCode: this.referCode,
            token: this.token,
          }),
        });
        const data = await response.json();
        console.log('data : ', data);
        if (data.success) {
            localStorage.setItem('referCode', data.data);
            this.isCreating = false;
            this.isRefer = true;
        } else {
            this.isCreating = false;
        }
      } catch (error) {
        console.error('Error verifying user:', error);
        this.isCreating = false;
        //this.$router.push({ name: 'Home' });
      }
    },
    updateRefer() {
        this.referCode = '';
        this.isRefer = false;
    }


    
  },
}




</script>

<style>

    .refer-box {
    margin-block: 40px;
    width: 90%; /* Prendre toute la largeur du parent */
    border-radius: 6px;
    background: #ffffffcc;
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px #00000033;
    

}

  .title {
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    color: #7fadc7;
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif;
  }


  .nameBtn {
  
    margin-right: 5px;
    width: 70%;
    min-width: 50px;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
  }

  .btnCtn {
    display: flex;
    justify-content: center;
  }

  .boxBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 13px;
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }

  input[type="code"] {
    
    margin: 5px;
    width: 80%;
    height: 50px;
    border-radius: 0px;
    border-color: #ff555550;
    border-width: 1px;
    background-color: rgb(255, 249, 249);
    transition: all 0.3s ease;
    outline: none;
    resize: vertical;
    overflow: hidden;
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    text-align: center;
    font-size: 2vh;
    font-weight: bold;

  }
  
  input[type="code"]:focus {
    margin: 5px;
    height: 50px;
    border-radius: 0px;
    background-color: transparent;
    font-family: 'Josefin Sans', sans-serif;
    color: #ff5555;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
  }
  input[type="code"]:hover {
    margin: 5px;
    height: 50px;
    border-radius: 0px;
    background-color: transparent;
    font-family: 'Josefin Sans', sans-serif;
    color: #ff5555;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
  }
  
  input[type="code"]::placeholder {
    margin: 5px;
    height: 50px;
    color: #ff5555;
  }


</style>