<template>

    <div class="spinner" :style="{ width: width, height: width }">
        

    </div>
  
    </template>
    
    <script>
    export default {
      name: "LoadingSpinner",
      props: {
        width: {
      type: String,
      required: true
    },
      
    },
      data() {
        return {

        };
      },
  
      created() {
  
    
    
      },
      methods: {
      
    
    
    
      }
    };
    </script>
    
    <style scoped>
    .spinner {
        border-top: 3px solid #ff5555; /* Blue */
        border-radius: 50%;
        animation: spin 1s linear infinite;
        
      }
  
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

    </style>
    