import { initializeApp } from "firebase/app";
import { getAuth ,GoogleAuthProvider } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyA31ipPzxWx5rwVoFjSomhHD6veJsR2Vcg",
  authDomain: "swingi-f08b6.firebaseapp.com",
  projectId: "swingi-f08b6",
  storageBucket: "swingi-f08b6.appspot.com",
  messagingSenderId: "557449151701",
  appId: "1:557449151701:web:202b8b3d9ef54e4eebce5c",
  measurementId: "G-6K9HVBNL2Z"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = "en";
const provider = new GoogleAuthProvider();




// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfCGWcqAAAAAHRATe9JJKnkpRkMveL-5KPWARsV'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export { auth, provider, appCheck};